.component:hover {
  background-color: #F5F5F5;
}
.component--completed {
  color: #BDBDBD;
}
.component--in-progress {
  color: #008009;
}
.component--cancelled {
  color: #BDBDBD;
  text-decoration: line-through;
}
.component td {
  vertical-align: middle;
}
.component td:nth-child(2) :global .bui-icon {
  cursor: help;
}
.component__from,
.component__to,
.component__room {
  white-space: nowrap;
}
.component__cancel:global(.bui-button) {
  padding: 1ex 0.5em;
  border-color: #e21111;
}
.component__cancel :global(.bui-button__icon) {
  margin-right: 0;
  color: #e21111;
}
.component__cancel[disabled] {
  border-color: var(--bui_color_grayscale_light);
}
.component__cancel[disabled] :global(.bui-button__icon) {
  color: var(--bui_color_grayscale_light);
}
