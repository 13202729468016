.demo-icon {
  display: inline-block;
  width: 130px;
  margin: 3ex 0;
}
.demo-icon > :global(.bui-icon) {
  display: block;
  margin: 0 auto 1.5ex auto;
}
.demo-icon .__label {
  color: #bebebe;
  text-align: center;
}
