/*

Please follow BEM naming and structure conventions. Avoid unnecessary inheritance and nesting.

http://getbem.com/naming/

*/
html {
  height: 100%;
  width: 100%;
}
label {
  cursor: pointer;
}
.s-body {
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  position: relative;
}
header,
main,
footer {
  flex-shrink: 0;
}
.s-header {
  background-color: #001B42;
  color: #fff;
}
.s-header--top {
  align-items: center;
  min-height: 70px;
}
.s-header--top__button {
  position: absolute;
  height: 24px;
  width: 24px;
  background: transparent;
  border: none;
  top: 8px;
  right: 8px;
  cursor: pointer;
  color: #fff;
}
.s-header--top__icon {
  margin-top: 2px;
}
.s-header--top__wrap {
  position: relative;
}
.s-top__input {
  color: #f7f7f7 !important;
  background-color: #1A3255 !important;
  border-color: #1A3255 !important;
  width: 50% !important;
  transition: all 0.5s ease;
}
.s-top__input::placeholder {
  color: #bebebe;
  font-weight: 400;
}
.s-top__input:focus {
  color: #1A3255 !important;
  background-color: #fff !important;
  width: 100% !important;
  outline: none !important;
  font-weight: 700;
}
.s-top__input:focus + .s-header--top__button {
  color: #1A3255;
}
.s-logo {
  margin-right: 8px;
}
.s-main-body {
  flex-grow: 1;
}
.s-main-body--center {
  display: flex;
}
.s-main-body--center__centered {
  align-items: center;
  justify-content: center;
  width: 100%;
}
/* Main SB */
.s-main-sb {
  display: flex;
  background-color: #febb02;
  padding: 4px;
  border-radius: 4px;
  margin: 24px 0 16px;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.s-main-sb:hover,
.s-main-sb:focus {
  box-shadow: 0 0 0 1px #d59c00 inset;
}
.s-main-sb__input-wrap {
  display: flex;
  flex-grow: 1;
}
.s-main-sb__input {
  border: 0;
  border-radius: 2px;
  flex-grow: 1;
  height: 50px;
  margin-right: 4px;
  padding: 0 16px;
  width: 100%;
  outline: none;
}
.s-main-sb__button {
  flex-grow: 0;
}
/* sr - search results */
.s-results__header {
  display: flex;
  align-items: center;
}
.s-results__abstract {
  overflow: hidden;
}
.s-results__sort {
  white-space: nowrap;
}
.s-results__title {
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
  margin-right: 16px;
}
.s-result__link {
  text-decoration: none;
}
.s-result__link:hover {
  text-decoration: underline !important;
}
.s-result__title {
  color: #383838;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.s-result__url {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.s-result__abstract {
  color: #707070;
}
.s-result__date {
  color: #bebebe;
}
/* filters */
.s-filters__title {
  font-weight: normal;
}
.s-filters__subtitle {
  font-weight: normal;
  margin-top: 0;
  color: #707070;
}
.s-checkbox__wrap {
  display: flex;
  margin-bottom: 8px;
}
.s-checkbox__label {
  flex-grow: 1;
}
.s-checkbox__qty {
  padding: 2px 8px;
  background-color: #f7f7f7;
  color: #707070;
  border-radius: 2px;
  font-size: 12px;
}
/* pagination */
.s-pagination {
  background-color: #fff;
}
/* PR */
.s-container--bare {
  padding: 0;
}
.s-container--bleed {
  padding: 0 !important;
}
.s-container--tab-bleed {
  background-color: #fff;
  border-bottom: 1px solid #d9d9d9;
  padding: 0 !important;
}
.s-container--tab-bleed .bui-tab__nav {
  margin-bottom: -1px;
  padding: 0 16px;
}
th {
  text-align: left;
}
#progressIndicator {
  background: rgba(255, 255, 255, 0.75);
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 25%;
}
#progressIndicator > img,
.progressIndicator > img {
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.bui-table__cell .bui-tooltip {
  display: none;
}
.bui-table__cell:hover .bui-tooltip {
  display: block;
  position: absolute;
  margin-left: 24px;
}
.bui-text-input__group.bui-text-input__group--with-button {
  display: block;
  position: relative;
}
.bui-text-input__group.bui-text-input__group--with-button .bui-form__control {
  display: block;
  overflow: hidden;
}
.bui-tab__nav {
  margin-bottom: 20px;
}
.mrt-error-message .bui-accordion__row-inner {
  padding: 1ex 1.5em;
}
