@import "~@bookingcom/bui/bui-variables.css";
.component :global(.bui-list__description-title) {
  margin-bottom: 0.5ex;
}
.component :global(.bui-list__item) {
  padding: 3ex 0.5em;
  margin: 0;
}
.component :global(.bui-list__item):first-child {
  padding-top: 3ex;
}
.component :global(.bui-list__item):last-child {
  padding-bottom: 3ex;
}
.component :global(.bui-list__item):hover {
  background-color: var(--bui_color_primary_lighter);
}
.__list-item + .__list-item {
  margin-top: 0.5ex;
}
.__arrow {
  vertical-align: middle;
}
.__oldValue {
  padding: 0.1ex 0.2em;
  color: #c0341d;
  background-color: rgba(252, 180, 180, 0.6);
  border-radius: 4px;
}
.__newValue {
  padding: 0.1ex 0.2em;
  color: var(--bui_color_black);
  background-color: rgba(0, 128, 9, 0.2);
  border-radius: 4px;
}
.__show-more {
  text-align: center;
}
