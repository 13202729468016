@import "~@bookingcom/bui/bui-variables.css";
.bui-form__control[type=number][disabled] {
  background-color: var(--bui_color_grayscale_lighter);
  color: var(--bui_color_grayscale_light);
}
.room-edit-panel {
  padding-top: 4px;
}
.spinner {
  margin: 0 auto;
}
