.__buttons-cell {
  display: flex;
  justify-content: flex-end;
}
.__cancel:global(.bui-button) {
  padding: 1ex 0.5em;
  border-color: #e21111;
}
.__cancel:global(.bui-button) :global(.bui-button__icon) {
  color: #e21111;
}
.__cancel:global(.bui-button)[disabled] {
  border-color: var(--bui_color_grayscale_light);
}
.__cancel:global(.bui-button)[disabled] :global(.bui-button__icon) {
  color: var(--bui_color_grayscale_light);
}
.__buttons {
  display: flex;
  justify-content: flex-end;
}
.__buttons :global(.bui-button) {
  margin-left: 0.5em;
}
