.dates {
  text-align: right;
  color: var(--bui_color_grayscale);
}
.users {
  text-align: right;
  color: var(--bui_color_grayscale);
}
.route {
  text-align: right;
  color: var(--bui_color_grayscale);
}
