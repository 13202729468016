.legend {
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
}
.fieldset {
  border: 1px solid gainsboro;
  padding: 24px;
}
.bui-legend {
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
}
.bui-fieldset {
  border: 1px solid gainsboro;
  padding: 24px;
}
